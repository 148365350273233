// Body

body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(5);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: middle;
		margin: 0 2px;
	}

	button {
		width: 8px;
		height: 8px;
		display: block;
		margin-right: 5px;
		background-color: $gy-b;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $br-a;
		width: 12px;
		height: 12px;
	}

	button:focus,
	button:hover {
		background-color: $br-a;
	}
	@include media-breakpoint-down(sm) {
		button {
			width: 8px;
			height: 8px;
		}
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 300px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

#home-video-wp {
	position: relative;
	z-index: 1;
	/*min-height: px(600);*/
	@include media-breakpoint-down(sm) {
		/*min-height: px(300); */
	}
}

#home-intro {
	background: $ye-a;

	.container {
		z-index: 2;
		position: relative;
		padding: px(100);
	}

	.avatar {
		position: absolute;
		top: px(-60);
		right: 0;
	}
	@include media-breakpoint-down(md) {
		.container {
			padding: px(75) px(15);
		}
	}
	@include media-breakpoint-down(sm) {
		.avatar {
			display: block;
			width: px(150);
			margin: px(-20) auto px(30);
			position: relative;
			top: auto;
		}

		.container {
			padding: px(50) px(15);
		}
	}
	@include media-breakpoint-down(xs) {
		.avatar {
			margin: px(-10) auto px(30);
		}

		.container {
			padding: px(30) px(15);
		}
	}
}

#home-skills {
	background-color: $br-a;
	color: $wh-a;

	.container {
		z-index: 2;
		position: relative;
		padding: px(100) px(100) px(250);

		&:after,
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			background-repeat: no-repeat;
		}

		&:before {
			left: px(-100);
			width: 325px;
			height: 229px;
			background-image: url("../images/illustrations/bg-decoration-left.png");
		}

		&:after {
			right: px(-180);
			width: 394px;
			height: 316px;
			background-image: url("../images/illustrations/bg-decoration-right.png");
		}
	}
	@include media-breakpoint-down(md) {
		.container {
			padding: px(75) px(15);

			&:after,
			&:before {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding: px(50) px(15);
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding: px(30) px(15);
		}
	}
}

#home-how {
	background-color: $gn-a;
	color: $wh-a;

	.container {
		z-index: 2;
		position: relative;
		padding: px(100) px(100) px(250);
	}
	@include media-breakpoint-down(md) {
		.container {
			padding: px(75) px(15) px(200);
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding: px(30) px(15) px(150);
		}
	}
	@include media-breakpoint-down(xs) {
		.container {
			padding: px(30) px(15);
		}
	}
}

#home-step {
	background-color: $br-a;
	position: relative;

	&:after {
		content: "";
		background-image: url("../images/illustrations/bg-home-green.png");
		background-repeat: no-repeat;
		height: 444px;
		width: 2500px;
		position: absolute;
		top: 0;
		display: block;
		left: 50%;
		margin-left: px(-1250);
		z-index: 2;
		background-position: top center;
		@include media-breakpoint-down(lg) {
			background-size: 1800px;
		}
		@include media-breakpoint-down(md) {
			background-size: 1250px;
		}
	}

	.container {
		z-index: 3;
		position: relative;
		padding: px(100) px(100) px(80);
	}
	@include media-breakpoint-down(md) {
		.container {
			padding: px(100) px(15);
		}
		@include media-breakpoint-down(sm) {
			.container {
				padding: px(50) px(15);
			}
		}
		@include media-breakpoint-down(sm) {
			.container {
				padding: px(30) px(15);
			}
		}
	}

	.step-wp {
		margin-top: px(-250);
		@include media-breakpoint-down(sm) {
			margin-top: px(-150);
		}
		@include media-breakpoint-down(xs) {
			margin-top: px(0);

			.col-sm-4 {
				width: px(280);
				margin: 0 auto;
			}
		}

		.item {
			position: relative;

			.content {
				text-align: center;
				position: absolute;
				width: 100%;
				padding: px(40) px(30) 0;
				height: 100%;
				top: 0;

				.nb {
					font-weight: 600;
					font-size: px(40);
					margin-bottom: px(20);
				}

				.title {
					font-size: px(18);
					line-height: 1.2em;
				}
			}
			@include media-breakpoint-down(lg) {
				.content {
					.nb {
						font-size: px(32);
						margin-bottom: px(10);
					}

					.title {
						font-size: px(16);
					}
				}
			}
			@include media-breakpoint-down(md) {
				.content {
					padding-top: px(30);

					.nb {
						font-size: px(28);
						margin-bottom: px(5);
					}

					.title {
						font-size: px(14.5);
					}
				}
			}
			@include media-breakpoint-down(sm) {
				.content {
					padding: px(15) px(15) 0;

					.nb {
						font-size: px(18);
						margin-bottom: px(5);
					}

					.title {
						font-size: px(12);
					}
				}
			}
			@include media-breakpoint-down(xs) {
				.content {
					padding: px(40) px(24);

					.nb {
						font-size: px(30);
						margin-bottom: px(5);
					}

					.title {
						font-size: px(17);
					}
				}
			}
		}
	}
}

#home-step-bis {
	background-color: $br-a;
	.link-wp {
		display: inline-block;
		img {
			transition: all 0.2s ease-in-out;
		}
		&:hover,
		&:focus {
			img {
				transform: scale(0.9);
			}
		}
	}
	.container {
		z-index: 4;
		position: relative;
		padding: 0 px(100) px(100);
	}

	.second {
		.item {
			color: $wh-a;
			position: relative;
			height: 100%;
			padding-left: px(20);
			padding-right: px(20);

			&.bordered {
				border-right: 2px solid $ye-a;
			}
		}
		@include media-breakpoint-down(md) {
			.item {
				text-align: center;

				&.bordered {
					&:after {
						content: "";
						width: px(50);
						height: px(2);
						background-color: $ye-a;
						position: absolute;
						bottom: px(-15);
						left: 50%;
						margin-left: px(-25);
					}
					border-right: none;
				}
			}
		}
	}

	.first {
		text-align: center;
		position: relative;
		display: block;

		&:after {
			content: "";
			display: block;
			width: 89px;
			height: 212px;
			background-image: url("../images/illustrations/home-arrow.png");
			position: absolute;
			z-index: 10;
			top: px(-90);
			left: px(-20);
		}

		&:before {
			content: "Mes besoins :";
			position: absolute;
			top: px(130);
			left: px(80);
			color: $wh-a;
		}

		img {
			margin: 0 px(30);
		}
	}
	@include media-breakpoint-down(lg) {
		.container {
			padding-top: px(10);
		}

		.first {
			&:after {
				top: px(-90);
				left: px(-70);
			}

			&:before {
				left: px(15);
			}
		}
	}
	@include media-breakpoint-down(md) {
		.container {
			padding-top: 0;
			padding-bottom: px(50);
		}

		.first {
			&:before {
				top: px(-10);
				left: 0;
				width: 100%;
				text-align: center;
			}
		}

		img {
			width: px(180);
			margin: 0 px(15);
		}
	}
	@include media-breakpoint-down(sm) {
		.container {
			padding-top: px(40);
			padding-left: px(15);
			padding-right: px(15);
		}
	}
	@include media-breakpoint-down(xs) {
		.first {
			&:after {
				left: 50%;
				margin-left: px(-125);
				top: px(-125);
				transform: scale(.4);
			}

			&:before {
				top: px(-35);
			}

			img {
				width: 200px;
			}
		}

		.container {
			padding-top: px(15);
		}
	}
}

#home-most {
	&.except {
		position: relative;
		z-index: 10;
		background-color: transparent;

		.item {
			position: relative;
			box-shadow: 0 0 15px rgba(0,0,0,0);
			transition: all 0.2s ease-in-out;

			.category {
				margin-top: px(30);
				background-color: $ye-a;
				color: $wh-a;
				line-height: 1em;
				display: inline-block;
				padding: px(6) px(8);
				color: $bk-a;
				margin-bottom: px(15);
				text-transform: uppercase;
				font-weight: 600;
				font-size: px(11);
				transition: all 0.2s ease-in-out;
			}

			.title {
				margin-top: 0;
				line-height: 1.3em;
			}

			.btn-wp {
				position: absolute;
				bottom: px(30);
				left: 50%;
				background-color: $wh-a;
				margin-left: px(-50);
				display: inline-block;
				padding: px(5) px(10);
				font-size: px(13);
				margin-top: px(15);
				font-weight: 600;
				color: $br-a;
				transition: all 0.2s ease-in-out;
			}
			@include media-breakpoint-down(xs) {
				.btn-wp {
					position: relative;
					left: auto;
					bottom: auto;
					margin: px(20) auto 0;
				}
			}

			&:focus,
			&:hover {
				box-shadow: 0 0 15px rgba(0,0,0,0.1);

				.btn-wp {
					background-color: $br-a;
					color: $wh-a;
				}
			}
		}
	}
	background-color: $br-a;

	.container.first {
		z-index: 3;
		position: relative;
		padding: 0 px(120) px(50);
		@include media-breakpoint-down(md) {
			padding: 0 px(80) px(20);
		}
		@include media-breakpoint-down(sm) {
			padding: 0 px(15) px(20);
		}
	}
	@include media-breakpoint-down(xs) {
		.container.second {
			.col-md-4 {
				width: 300px;
				margin: 0 auto;
			}
		}
	}

	.item {
		padding: px(20) px(10);
		background-color: $gy-a;
		height: 100%;
		display: block;
		border-radius: 10px;
		text-align: center;
		padding-bottom: px(100);
		transition: all 0.2s ease-in-out;

		img {
			display: block;
			margin: 0 auto;
			transition: all 0.2s ease-in-out;
		}

		&:focus,
		&:hover {
			background-color: $wh-a;

			img {
				transform: scale(1.05);
			}
		}

		.title {
			margin-top: px(30);
			font-size: px(18);
			font-weight: 600;
		}

		.price {
			color: $br-a;
			font-size: px(15);
			margin-top: px(10);
		}
		@include media-breakpoint-down(sm) {
			padding-bottom: px(30);
		}
	}
}

#home-blog {
	position: relative;

	.container {
		z-index: 2;
		position: relative;
		padding: px(140) px(100);

		.bg-left {
			height: 100%;
			width: px(455);
			position: absolute;
			left: px(-400);
			top: 0;
			background-size: cover;
			background-image: url("../images/illustrations/bg-blog-left.png");
		}

		.bg-right {
			height: 100%;
			width: px(694);
			position: absolute;
			right: px(-350);
			top: 0;
			background-size: cover;
			background-image: url("../images/illustrations/bg-blog-right.png");
		}

		.bg-bottom {
			height: px(198);
			width: px(1627);
			position: absolute;
			right: px(-600);
			bottom: px(-60);
			background-repeat: no-repeat;
			background-image: url("../images/illustrations/bg-blog-bottom.png");
		}
		@include media-breakpoint-down(md) {
			.bg-left {
				width: px(800);
				left: px(-750);
			}

			.bg-right {
				right: px(-200);
			}

			.bg-bottom {
				right: px(-1000);
			}
		}
		@include media-breakpoint-down(xs) {
			padding: px(30) px(15) px(75);

			.bg-bottom,
			.bg-left,
			.bg-right {
				display: none;
			}
		}
	}

	.item {
		display: block;
		position: relative;

		img {
			transition: all 0.2s ease-in-out;
		}

		&:focus,
		&:hover {
			.title {
				background-color: $bk-a;
				color: $wh-a;
			}

			img {
				transform: scale(1.025);
			}
		}

		.title {
			background-color: $ye-a;
			text-align: center;
			display: inline-block;
			line-height: 1em;
			padding: px(5);
			margin-bottom: px(5);
			transition: all 0.2s ease-in-out;
		}
	}
}

.item-contact {
	.title-contact {
		position: relative;
		padding-left: 23px;
		font-weight: 600;
		font-size: px(15);
		color: $bk-a;
	}

	.title-contact .material-icons {
		position: absolute;
		color: $bk-a;
		top: 1px;
		left: -2px;
		font-size: px(18);
	}

	.list {
		margin-top: 8px;
		padding-left: 25px;
	}

	.list li {
		display: block;
		position: relative;
		padding-left: 20px;
		font-size: px(14);
		line-height: 1.4em;
		word-break: break-word;
		color: $br-a;
	}

	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 0;
		width: 8px;
		height: 2px;
		background-color: $br-a;
	}
	@include media-breakpoint-down(sm) {
		float: left;
		width: 50%;
	}
	@include media-breakpoint-down(xs) {
		float: left;
		width: 100%;
	}
}

.form-contact {
	padding: 25px 30px 35px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;

	&.except-table {
		padding: 0;
		overflow: hidden;
	}

	&.except-b {
		padding-bottom: 25px;
	}

	.label-bis {
		font-size: px(13);
		color: $br-a;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 2px;
	}

	.form-control-bis {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 1px solid shade($gy-a, 10%);
		color: $br-a;
		font-size: px(13);
		padding: 6px 0;
		outline: none !important;
		-webkit-appearance: none;
		border-radius: 0 !important;
		box-shadow: none !important;
		transition: all 0.2s ease-in-out;
	}

	textarea.form-control-bis {
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.form-control-bis.active,
	.form-control-bis:focus {
		border-bottom-color: $br-a;
		color: $bk-a;
	}

	.form-control-bis::placeholder {
		color: tint($bk-a, 50%);
		font-style: italic;
		font-size: px(13);
		opacity: 1;
		font-size: px(13);
	}

	.input-caption {
		font-size: px(11);
		font-style: italic;
		color: $gy-a;
		margin-top: 10px;
	}

	.dropdown-toggle {
		border: none;
		border-bottom: 1px solid $gy-a;
		border-radius: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.datepicker-wp .ui-datepicker-trigger {
		opacity: 0.4;
		right: 3px;
		margin-top: -14px;
	}

	.datepicker-wp .form-control-bis {
		padding-right: 25px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 20px 25px !important;
	}
}

.service-header {
	position: relative;
	background-image: url("../images/illustrations/blog-bg.jpg");
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: $wh-a;
	text-align: center;
	color: $wh-a;
	padding: px(75) 0;

	&:before {
		content: "";
		width: 100%;
		height: px(150);
		position: absolute;
		top: 0;
		left: 0;
		background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: rgba(0,0,0,0.3);
	}

	.container {
		position: relative;
		z-index: 2;
		padding: 0 px(150);
	}

	.title-cat {
		color: $wh-a;
		text-transform: uppercase;
		font-size: px(14);
		font-weight: 600;
		margin: px(15) 0;
		text-shadow: 0 0 5px rgba(0,0,0,0.8);
	}

	.title {
		font-size: px(38);
		color: $wh-a;
		font-weight: 600;
		line-height: 1.3em;
		text-shadow: 0 0 5px rgba(0,0,0,0.8);
		margin-bottom: 0;

		&:before {
			content: "“";
			vertical-align: top;
			margin-right: px(10);
		}

		&:after {
			content: "”";
			vertical-align: bottom;
			margin-left: px(10);
		}

		&:after,
		&:before {
			display: 400;
			position: relative;
			display: inline-block;
			font-size: 200%;
			font-family: "Times New Roman", Times, serif;
		}
	}
	@include media-breakpoint-down(lg) {
		padding: px(65) 0 px(50);

		.container {
			padding: 0 px(75);
		}

		.img-fluid {
			max-width: px(170);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(40) 0;

		.img-fluid {
			max-width: px(150);
		}

		.container {
			padding: 0 px(15);
		}

		.title {
			font-size: px(32);
			margin-top: 0;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(30) 0;

		.title {
			font-size: px(30);
		}
	}
	@include media-breakpoint-down(xs) {
		background-attachment: inherit;

		&:before {
			display: none;
		}

		.img-fluid {
			display: none;
		}

		.title {
			font-size: px(26);
		}
	}
}

.service-form-wp {
	.content {
		position: relative;
		border-radius: 10px 10px;
		overflow: hidden;
		background-color: $gy-a;

		#room-wp.active {
			.type-room {
				display: block;
			}

			.placeholder {
				display: none;
			}
		}

		.room-master {
			display: none;
		}

		.placeholder {
			font-size: px(14);
			color: $br-a;
			line-height: 1.2em;
		}

		#room-wp {
			box-shadow: 0 2px 5px -3px inset rgba(0,0,0,0.5);
		}

		.item {
			&.except-switch{
				background-color: rgba(255,255,255,0.5);
				padding-top: px(13);
				padding-bottom: px(13);
			}
			&:first-child {}
			padding: px(20);
			border-bottom: 1px solid shade($gy-a, 10%);

			&.placeholder {
				padding-top: px(12.5);
				padding-bottom: px(12.5);
				background-color: rgba(255,255,255,0.5);
			}

			&.type-column {
				display: flex !important;
				align-items: center;
				padding-top: px(12.5);
				padding-bottom: px(12.5);
				background-color: rgba(255,255,255,0.5);

				.col-left {
					flex: 0 0 75%;
					max-width: 75%;

					.txt {
						font-size: px(11);
						font-weight: 600;
						text-transform: uppercase;
						color: $br-a;
					}
				}

				.col-right {
					flex: 0 0 25%;
					max-width: 25%;
					position: relative;

					.price {
						color: $br-a;
						text-align: right;
						font-weight: 600;
						font-size: px(16);
						display: block;
					}

					input {
						max-width: calc(100%);
						border: none;
						text-align: right;
						display: inline-block;
						font-size: px(13);
						padding: px(6) px(10);
						outline: none;
						border: 1px solid shade($gy-a, 10%);
						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button {
						  -webkit-appearance: none;
						  margin: 0;
						}
						&[type=number] {
						  -moz-appearance: textfield;
						}
					}
				}
				&.except-shopping-card {
					background-color: transparent;
					.col-right,
					.col-left {
						flex: 0 0 50%;
						max-width: 50%;
					}
					&.last {
						border: none;
					}
				}
				&.except-promo {
					@include media-breakpoint-down(lg) {
						.col-left{
							max-width: 60%;
							flex: 0 0 60%;
						}
						.col-right  {
							max-width: 40%;
							flex: 0 0 40%;
						}
						.input-room {
							width: 100%;
						}
					}
				}
			}

			&.type-total {
				background-color: rgba(255,255,255,0);
				border: none;

				.txt {
					font-size: px(12) !important;
				}
			}
		}

		.dropdown-toggle {
			border: none;
			font-size: px(13);
			display: block;
			line-height: 1em;
			padding-top: px(11);

			.filter-option-inner-inner {
				display: block;
				padding-bottom: px(2);
				line-height: 1em;
			}
		}
	}

	.btn-wp {
		margin-top: px(20);
		position: relative;
		overflow: hidden;
		&:after {
			content:"";
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.35);
			position: absolute;
			top: 0;
			left: 0;
		}
		&.active {
			&:after {
				display: none;
			}
		}

		.btn-a {
			padding: px(15);
			display: block;
			width: 100%;
			border-radius: 30px;
		}
	}
}


.service-txt {
	font-size: px(16);
	line-height: 1.5em;
	color: $br-a;

	> *:last-child {
		margin-bottom: 0;
	}
	ul {
		margin: px(20) 0;
		padding-left: px(30);
	}
	li {
		display: block;
		position: relative;
		padding-left: px(15);
		margin-top: px(5);
		&:before {
			content:"•";
			font-size: px(26);
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@include media-breakpoint-down(md) {
		font-size: px(15);
	}
}


.portfolio-galery {
	padding: px(10) px(30) px(30) px(30);
	background-color: $gy-a;
	.item {
		display: block;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:focus {
			opacity: .8;
		}
	}
}
