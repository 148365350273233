// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		//display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 2px 6px rgba(0,0,0,0.1);
	color: $br-a;
	height: px(75);

	&:after {
		content: "";
		background-image: url("../images/icons/header-triangle.png");
		width: 160px;
		height: 33px;
		display: block;
		position: absolute;
		bottom: px(-33);
		left: 50%;
		margin-left: px(-80);
	}

	.container {
		position: relative;
	}

	#logo {
		position: absolute;
		top: 0;
		left: 0;
	}

	#nav-secondary {
		position: absolute;
		top: px(16);
		right: px(15);
		text-align: right;
		padding-right: px(60);
		line-height: 1em;

		.phone {
			display: block;
			margin-top: px(2);
		}

		.list-wp {
			font-size: px(14);

			.item {
				display: inline-block;

				.link:hover,
				.link:focus {
					color: $bk-a;
				}
				.link.active {
					font-weight: 600;
				}

				&:not(:last-child):after {
					content: "|";
					font-size: px(12);
					vertical-align: middle;
				}

				.logout .material-icons {
					font-size: px(16);
					position: relative;
					top: px(4);
				}
			}
		}

		.shopping-card-wp {
			position: absolute;
			top: px(6);
			right: 0;

			.shopping-card {
				> * {
					display: inline-block;
					vertical-align: text-top;

					&.nb {
						position: relative;
						top: px(-5);
					}
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		height: px(60);

		#logo {
			width: px(110);
		}
		#nav-secondary {
			top: px(10);
		}

		&:after {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: px(25);
		height: px(100);

		#nav-secondary {
			position: relative;
			text-align: left;
			.shopping-card-wp {
				top: px(-40);
			}
			&:after{
				content:"";
				width: 300%;
				height: px(25);
				display: block;
				position: absolute;
				background-color: $gy-a;
				top: px(-85);
				left: -100%;
			}
			.list-wp {
				position: absolute;
				z-index:2;
				top: px(-90);
				padding: px(5) px(10);
				left: p(15);
				width: 100%;
				background-color: $gy-a;
			}
			.phone {
				z-index: 2;
				position: absolute;
				top: px(-82);
				right: px(15);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		padding-top: 0;
		height: auto;
		min-height: px(61);
		.container {
			padding: 0;
		}
		#nav-secondary {
			position: static;
			.shopping-card-wp {
				top: px(15);
				right: px(75);
				left: auto;
				position: absolute;
			}
			background-color: transparent;
			&:after {
				display: none !important;
			}
			.list-wp,
			.phone {
				display: none;
			}
		}
		#logo {
			width: px(70);
		}
	}

}
// Navigation :: Main

#nav-main {
	width: 100%;
	display: block;
	text-align: center;
	padding-top: px(15);
	margin-left: px(-45);

	.item {
		vertical-align: middle;
		display: inline-block;

		&:nth-child(2) {
			margin: 0 px(65);
		}
		&.mobile-only {
			display: none;
		}
	}

	.link {
		color: $br-a;
		line-height: 1.1em;
		display: block;
		font-size: px(17);
		position: relative;
		&:after {
			content:"";
			position: absolute;
			bottom: px(-5);
			left: 50%;
			margin-left: px(-15);
			width: px(30);
			height: px(1);
			background-color: $br-a;
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}
		&:hover:after,
		&:focus:after,
		&.active:after {
			opacity: 1;
		}

		&.active {
			font-weight: 600;
		}

		&.logo {
			font-weight: 700;
			font-size: px(22);
			&:after {
				display: none;
			}
		}
	}

	.link:focus,
	.link:hover {}

	.link.active {}

	.nav-sub {
		.item-sub {}

		.link-sub {}

		.link-sub:focus,
		.link-sub:hover {}

		.link-sub.active {}
	}
	@include media-breakpoint-down(lg) {
		.item {
			&:nth-child(2) {
				margin: 0 px(30);
			}
		}
	}
	@include media-breakpoint-down(md) {
		text-align: left;
		padding-left: px(160);

		.item {
			margin-right: px(15);

			&:nth-child(2) {
				display: none;
			}
		}

		.link {
			font-size: px(14);

			&.logo {
				font-size: px(18);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: px(20);
	}
	@include media-breakpoint-down(xs) {
		display: none;
		width: 100%;
		margin: 0;
		padding: 0;
		padding-top: px(61);
		.item {
			display: block;
			margin: 0;
			background-color: $gy-a;
			padding:  px(2.5) px(15);
			&:first-child {
				padding-top: px(10);
			}
			&:last-child {
				padding-bottom: px(10);
			}
			.link {
				padding: px(5) 0;
			}
			br {
				display: none;
			}
			&.mobile-only {
				display: block;
			}
		}
	}
}
#nav-mobile-trigger{
	background-color: $br-a;
	position: absolute;
	top: 0;
	right: 0;
	width: px(61);
	height: px(61);
}


#header-page {
	background-color: $gy-a;
	text-align: center;
	padding: px(55) 0 px(40);
	&.except-green {
		background-color: $gn-a;
		.title {
			color: $wh-a;
			&:after {
				background-color: $wh-a;
			}
		}
	}
	.title {
		font-weight: 600;
		font-size: px(30);
		text-transform: uppercase;
		display: inline-block;
		color: $br-a;
		position: relative;
		margin-bottom: 0;
		&:after {
			content:"";
			position: absolute;
			bottom: px(0);
			left: 50%;
			margin-left: px(-10);
			width: px(20);
			height: px(2);
			background-color: $br-a;
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
		.title {
			font-size: px(24);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(25) 0;
		.title {
			font-size: px(22);
		}
	}
	@include media-breakpoint-down(xs) {
		.title {
			font-size: px(20);
		}
	}
}
