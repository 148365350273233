// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text

.form-control {
	color: $bk-a !important;
	border-color: $bk-a;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus {
		border-color: $bk-a;
		box-shadow: none;
		color: $bk-a;
		background: $wh-a;
	}
}
// Input :: Text Placeholder

.form-control.placeholder::placeholder {
	color: $bk-a;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {
	position: relative;
	display: block;

	> label,
	> input {
		cursor: pointer;
	}

	> input {
		position: absolute;
		-moz-opacity: 0;
		opacity: 0;
		filter: alpha(opacity = 0);
	}

	> input[type=checkbox] + label,
	> input[type=radio] + label {
		display: block;
		padding: 0 0 0 35px;
		font-size: rem(13);
		font-weight: 300;
		color: $bk-a;
		background-image: url("../images/icons/sprite-checkbox_radio.png");
		line-height: 1.3em;
		background-repeat: no-repeat;
		background-size: 17px;
	}

	> input[type=radio] + label {
		background-position: 0 -168px;
	}

	> input[type=checkbox] + label {
		background-position: 0 -537px;
	}

	> input[type=radio]:checked + label {
		background-position: 0 0;
	}

	> input[type=checkbox]:checked + label {
		background-position: 0 -352px;
	}
}
// Input :: Radio ON/OFF

.btn-switch-wp {
	position: relative;
	display: flex;
	align-items: center;

	.label {
		padding-left: 15px;
		color: $br-a;
		line-height: 1em;
		font-weight: 400;
		font-size: px(13);
		cursor: pointer;
		span {
			font-weight: 600;
			font-style: italic;
			font-size: px(14);
			margin-top: px(3);
			display: block;
		}
	}

	.checkbox-toggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}

	.checkbox-toggle + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.checkbox-toggle-round + label {
		margin: 0;
		padding: 2px;
		width: 38px;
		height: 21px;
		background-color: $gy-b;
		border-radius: 60px;
		transition: background 0.2s;
	}

	.checkbox-toggle-round + label:after,
	.checkbox-toggle-round + label:before {
		display: block;
		position: absolute;
		content: "";
	}

	.checkbox-toggle-round + label:after {
		top: 3px;
		left: 3px;
		bottom: null;
		width: 15px;
		height: 15px;
		background-color: $wh-a;
		border-radius: 52px;
		transition: margin 0.2s, background 0.2s;
	}

	.checkbox-toggle-round:checked + label {
		background-color: $ye-a;
	}

	.checkbox-toggle-round:checked + label:after {
		margin-left: 17px;
	}
}
// Input :: Range

.range-wp {
	position: relative;
	padding: 0 px(10) px(30);

	.value-wp {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		top: px(30);
		left: 0;
	}

	.value {
		color: $bk-a;
	}

	.value.max {
		text-align: right;
	}

	.slider.slider-horizontal {
		width: 100%;
	}

	.slider-handle {
		background: $bk-a;
	}

	.slider-track {
		width: calc(100% + 20px) !important;
		left: -10px !important;
		background: tint($bk-a,75%) !important;
	}

	.slider-selection {
		background: tint($bk-a, 90%) !important;
	}
}
// Input :: Datepicker

.datepicker-wp {
	position: relative;

	.form-control {
		padding-right: px(30);
	}

	.ui-datepicker-trigger {
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: px(-8);

		.material-icons {
			font-size: px(18);
		}
	}
}

.ui-datepicker {
	font-size: px(14);
	background-image: none !important;
	border-radius: 0 !important;

	.ui-widget-header {
		background: tint($bk-a, 80%) !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.ui-button,
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default {
		border: none;
		background: tint($bk-a,95%);
	}

	.ui-datepicker-today .ui-state-highlight {
		border: none;
		background: tint($bk-a,70%);
	}

	.ui-button.ui-state-active:hover,
	.ui-button:active,
	.ui-state-active,
	.ui-widget-content .ui-state-active,
	.ui-widget-header .ui-state-active,
	a.ui-button:active {
		border: none;
		background: tint($bk-a,50%);
	}

	.ui-button:focus,
	.ui-button:hover,
	.ui-state-focus,
	.ui-state-hover,
	.ui-widget-content .ui-state-focus,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-hover {
		background: transparent !important;
	}
}
// Input :: File

.custom-file-wp {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;

	.custom-file-input {
		position: relative;
		z-index: 2;
		width: 100%;
		height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
	}

	.custom-file-label {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: calc(2.25rem + 2px);
		@extend .form-control;
	}

	.custom-file-label::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(calc(2.25rem + 2px) - 1px * 2);
		padding: 0 px(15);
		background-color: $bk-a;
		color: $wh-a;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.custom-file-input:lang(fr)~.custom-file-label::after {
		content: "Rechercher";
	}
}
// Input :: Select Structure

.selectpicker-wp {
	position: relative;

	.bootstrap-select {
		width: 100% !important;
	}

	.filter-option {
		@include ellipsis;
	}

	.dropdown-toggle {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: px(30) !important;
		@extend .form-control;
		outline: none;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 10px null null);
		margin-top: -3px;
		color: $bk-a;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
}

.bootstrap-select .dropdown-menu li a {
	font-size: px(12);
}
.dropdown-item.active, .dropdown-item:active {
	background-color: $br-a;
	color: $wh-a;
}
// Validation :: Error

.parsley-errors-list {
	color: #a94442;
	font-size: px(11);
	margin-top: px(5);
	.parsley-required {
		font-size: px(11);
	}
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: #a94442;
	color: #a94442 !important;
}

.form-control.parsley-error::placeholder {
	color: #a94442;
}





.form-wp {
	padding: 25px 30px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.05);
	border-radius: 8px;
	position: relative;
	.title {
		font-weight: 700;
		font-size: px(13);
		margin-bottom: px(5);
		color: $br-a;
		text-transform: uppercase;
	}
	ul li {
		font-size: px(13);
	}
	&.except-connexion {
		padding-bottom: px(115);
	}
	&.except-subscribe{
		padding-bottom: px(95);
		@include media-breakpoint-down(xs) {
		}
	}

	.connexion-wp {
		border-top: 1px solid $gy-a;
		background-color: tint($gy-a, 65%);
		position: absolute;
		padding: px(15);
		color: $gy-b;
		bottom: 0;
		left: 0;
		font-size: px(12);
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
		border-radius: 0 0 8px 8px;
		width: 100%;
	}

	.subscribe-wp {
		border-top: 1px solid $gy-a;
		background-color: tint($gy-a, 65%);
		position: absolute;
		padding: px(15) px(30);
		color: $gy-b;
		bottom: 0;
		left: 0;
		font-size: px(12);
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
		border-radius: 0 0 8px 8px;
		width: 100%;

	}

	.txt-small {
		color: $br-a;
		font-style: italic;
		font-size: px(12);
		display: block;
	}


	.input-wp {
		display: block;

		.label {
			font-size: px(13);
			color: $bk-a;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 2px;
			color: $br-a;
			font-weight: 600;
		}

		.input,
		.selectpicker-wp .dropdown-toggle {
			display: block;
			width: 100%;
			border: none;
			border-bottom: 1px solid shade($gy-a, 20%);
			font-size: px(12);
			padding: 6px 0;
			box-shadow: none !important;
			outline: none !important;
			-webkit-appearance: none;
			height: px(30);
			border-radius: 0 !important;
			color: $bk-a;
			transition: all 0.2s ease-in-out;

			&.except-textarea {
				height: px(170);
			}
			&::placeholder {
				color: shade($gy-b, 20%);
				opacity: 1;
			}
			&:focus {
				border-bottom: 1px solid $br-a;
			}

		}

		.input-link {
			text-decoration: underline;
			font-size: px(11);
			margin-top: px(8);
			font-style: italic;
			display: block;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 15px 20px;
	}
}
