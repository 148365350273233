// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$ye-a: #ffc700;
$gn-a: #019934;
$br-a: #6e6b62;
$gy-a: #f3f3f3;
$gy-b: #d3d2d0;
// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-ye-a {
  color: $ye-a;
}

.c-gn-a {
  color: $gn-a;
}

.c-br-a {
  color: $br-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}
// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-ye-a {
  background-color: $ye-a;
}

.bg-gn-a {
  background-color: $gn-a;
}

.bg-br-a {
  background-color: $br-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}
