// Font family
$font-stack: 'Raleway', sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity 0.1s ease-in-out;
  color: $bk-a;
}
// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
  color: $bk-a;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: px(28);
  font-weight: 600;
  line-height: 1.2em;
  display: inline-block;

  &.bordered {
    border-top-width: 3px;
    border-top-style: solid;
    padding-top: px(15);
  }

  &.black {
    color: $bk-a;

    &.bordered {
      border-top-color: $bk-a;
    }
  }

  &.white {
    color: $wh-a;

    &.bordered {
      border-top-color: $wh-a;
    }
  }
}

.h-like-c {
  font-size: px(18);
  font-weight: 500;
  line-height: 1.2em;
}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-c {
    font-size: px(16);
  }
  .h-like-a {
    display: block;
    font-size: px(25);
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    display: block;
    font-size: px(22);
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {
  font-size: px(16);
  line-height: 1.2em;
}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
