// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}



.sprite {
	&.back-top {
		width: 50px;
		height: 50px;
	}

	&.back-top {
		background-position: 0 0;
	}
	&.panier-icon {
		width:30px;
		height: 30px;
		background-position: -50px 0;
	}
	&.social-youtube,
	&.social-twitter,
	&.social-instagram,
	&.social-facebook {
		width: 60px;
		height: 70px;
	}
	&.social-youtube {
		background-position: -90px 0;
	}
	&.social-twitter {
		background-position: -150px 0;
	}
	&.social-instagram {
		background-position: -210px 0;
	}
	&.social-facebook {
		background-position: -270px 0;
	}
}
