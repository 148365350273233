.blog-item {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  .link-wp {
    background-color: tint($gy-a, 50%);
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    justify-content: space-between;

    .img-wp {
      position: relative;
      overflow: hidden;
      img {
        display: block;
        transition: all 0.2s ease-in-out;
      }
    }
    .content {
      padding: px(30) px(30) px(5);
      position: relative;

      > * {
        position: relative;
        z-index: 2;
      }

      .category-label {
        background-color: $ye-a;
        color: $wh-a;
        line-height: 1em;
        display: inline-block;
        padding: px(6) px(8);
        color: $bk-a;
        margin-bottom: px(15);
        text-transform: uppercase;
        font-weight: 600;
        font-size: px(13);
        transition: all 0.2s ease-in-out;
      }

      .title {
        border-top: 2px solid $bk-a;
        padding-top: px(10);
        font-size: px(18);
        font-weight: 600;
        line-height: 1.3em;
        color: $bk-a;
      }

      .txt {
        font-size: px(12.5);
        line-height: 1.6em;
        margin-top: px(5);
        color: $bk-a;
      }
      .date {
        font-size: px(12);
        margin: px(5) 0 0;
      }
    }

    .btn-wp {
      text-align: right;
      padding: px(0) px(30) px(25);
      display: block;
      .link {
        font-weight: 600;
        display: inline-block;
        padding: px(5) px(10);
        font-size: px(12);
        background-color: $wh-a;
        color: $br-a;
        transition: all 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      .img-wp {
        img {
          transform: scale(1.1);
        }
      }
      .content{
        .category-label {
          background-color: $bk-a;
          color: $wh-a;
        }
      }
      .link {
        background-color: $ye-a !important;
        color: $bk-a;
      }
    }

    &.except {
      .content:after {
        left: auto;
        right: 0;
        //@extend .decoration-bg-corner-right;
      }
    }

    &.except-portfolio {
      .content {
        padding: px(20) px(20) px(10);

        &:after {
          display: block;
          transform: scale(0.8) translate(-20px, -13px);
        }
      }
    }

  }
}

#slick-blog-bg  {
  padding: px(50) 0;
  background-image: url('../images/illustrations/blog-bg.jpg');
  background-attachment: fixed;
  &.except-portfolio {
    background-image: url('../images/illustrations/portfolio-bg.jpg');
  }
  @include media-breakpoint-down(md) {
    padding: px(30) 0;
  }
  @include media-breakpoint-down(xs) {
    padding: px(20) 0 px(30);
  }
}

#slick-blog-wp {
  position: relative;
  width: calc(100% + 20px);
  margin-left: px(-10);

  .slick-slide {
    outline: none !important;
  }

  .item {
    padding: px(10);
  }

  .link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $br-a;
    font-weight: 600;
    border-radius: 30px;
    background-color: $wh-a;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    height: px(55);
    font-size: px(13.5);
    line-height: 1.15em;
    padding: px(5) px(25);
    outline: none !important;
    text-align: center;
    transition: all 0.2s ease-in-out;

    &.active {
      background: $ye-a !important;
      border-color: $ye-a !important;
      color: $wh-a !important;
    }

    &:hover {
      // background-color: tint($gold-a, 50%);
      // color: shade($gold-b, 30%);
      background: $wh-a;
      border-color: $br-a;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
  @include media-breakpoint-down(md) {
    .link {
      font-size: px(13.5);
    }
  }
  @include media-breakpoint-down(sm) {

    .link {
      font-size: px(14.5);
    }


  }
  @include media-breakpoint-down(xs) {

    .link {
      font-size: px(12);
    }

  }

  .slick-dots {
    margin-bottom: 0;
    text-align: center;
    position: absolute;
    bottom: px(-25);
    width: 100%;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 2px;
    }

    button {
      width: px(10);
      height: px(10);
      margin-right: 5px;
      border-radius: 100%;
      background-color: $gy-b !important;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      text-indent: -999em;
      border: none;

      &:after {
        display: none;
      }
    }

    .slick-active button {
      background-color: $wh-a !important;
    }

    button:focus,
    button:hover {
      background-color: $wh-a !important;
    }
  }
}


.portfolio-item {
  height: 100%;
  .link-wp {
    background-size:cover;
    height: px(300);
    display: block;
    position: relative;
    border-radius: 10px;
    background-position: center;
    overflow: hidden;
    &:after {
      content:"";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.1);
      transition: all 0.2s ease-in-out;
    }
    .caption {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      z-index: 3;
      padding: px(20);
      background-color: rgba(0,0,0,0.4);
      transition: all 0.2s ease-in-out;
      .title {
        color: $wh-a;
        font-size: px(16);
        line-height: 1.3em;
        font-weight: 600;
        margin-top: px(5);
      }
    }
    .category-label {
      background-color: $ye-a;
      color: $wh-a;
      line-height: 1em;
      display: inline-block;
      padding: px(6) px(8);
      color: $bk-a;
      margin-bottom: px(10);
      text-transform: uppercase;
      font-weight: 600;
      font-size: px(10);
      transition: all 0.2s ease-in-out;
    }
    .btn-wp {
      transform: scale(1.2);
      opacity: 0;
      z-index: 0;
      position: absolute;
      width: px(100);
      text-align: center;
      font-size: 600;
      color: $br-a;
      font-weight: 600;
      margin: px(-17.5) 0 0 px(-50);
      padding: px(8) px(6);
      box-shadow: 0 0 5px rgba(0,0,0,0.5);
      top: 50%;
      left: 50%;
      display: inline-block;
      background-color: $wh-a;
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &:focus {
      &:after {
        background-color: rgba(0,0,0,0.3);
      }
      .btn-wp {
        opacity: 1;
        z-index: 4;
        transform: scale(1);
      }
      .caption {
        bottom: -100%;
      }
    }
  }
}
