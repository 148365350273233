// Footer

#footer-decoration {
	position: relative;
	padding-top: px(39);
	z-index: 30;
	margin-top: px(-39);
	&:after {
		content:"";
		background-image: url('../images/illustrations/footer-decoration.png');
		width: 200px;
		height: 39px;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 50%;
		margin-left: px(-100);
		background-size: 200px;
	}
	span{
		width: calc(50% - 99px);
		background-color: $br-a;
		position: absolute;
		top: 0;
		height: px(39);
		box-shadow:0 -3px 5px -3.5px rgba(0,0,0,0.4);
		&:first-child {
			left: 0;
		}
		&:last-child {
			right: 0;
		}
	}
}
#footer {
	position: relative;
	z-index: 40;
	background-color: $br-a;
	color: $wh-a;
	padding: px(30) 0;
	.container {
		position: relative;
		padding-left: px(185);
	}
	.list-nav {
		li {
			&:not(:first-child){
				margin-top: px(5);
			}
			a:hover,
			a:focus {
				text-decoration: underline;
			}
		}
	}

	.logo {
		position: absolute;
		top: 0;
		left: px(15);
	}
	.social {
		li {
			display: inline-block;
			vertical-align: middle;
			a {
				display: block;
				transition: all 0.2s ease-in-out;
				&:hover,
				&:focus {
					transform: translateY(-5px);
				}
			}
		}
	}
	.pixell {
		display: block;
		margin-top: px(14);
		font-size: 12px;
		img {
			width: 30px;
			display: inline-block;
			position: relative;
			top: -4px;
			margin-left: 5px;
		}
	}

	@include media-breakpoint-down(md) {
		.container {
			padding-left: px(15);
		}
		.logo {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		.logo {
			display: block;
			left: auto;
			right: 0;
		}

		.social {
			margin-top: px(15);
			li:first-child {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.logo {
			display: none;
		}
	}
}
