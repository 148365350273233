.table-wp {
  thead th {
    background-color: $br-a;
  }

  .image {
    width: 75px;
  }

  table {
    margin-bottom: 0;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    border: 1px solid $gy-a;

    .selectpicker-wp .dropdown-toggle {
      background-color: $gy-a;
      border: none !important;
      width: 55px;

      .filter-option-inner {
        padding: 0;
      }
    }

    th {
      background-color: $br-a;
      color: $wh-a;
      font-size: px(12);
      padding: px(15) px(20);
      border-right: 1px solid $gy-a;
      text-transform: uppercase;
      font-weight: 600;
      border-bottom: none !important;
    }

    .btn-download {
      color: $ye-a;
    }

    .btn-order {
      color: $br-a;
      .material-icons {
        font-size: px(20);
      }
      transition: all 0.2s ease-in-out;
    }

    .btn-modify {
      position: relative;
      text-transform: uppercase;
      font-weight: 400;
      font-size: px(8);
      display: inline-block;
      color: $wh-a;
      line-height: 1em;
      background-color: $gy-b;
      padding: px(6) px(8) px(5) px(16);
      margin-top: px(5);
      transition: all 0.2s ease-in-out;
      &:hover,
      &:focus {
        background-color: shade($gy-b, 10%);
      }
      .material-icons {
        position: absolute;
        top: 50%;
        left: px(2);
        margin-top: px(-5);
        font-size: px(10);
      }
    }

    .btn-order {
      &:focus,
      &:hover {
      }
    }




    .title-product {
      font-weight: 700;
      font-size: px(18);
      color: $br-a;
      line-height: 1.2em;
    }


    td {
      vertical-align: middle;
      padding: px(15) px(20);
      border-right: 1px solid $gy-a;
      font-size: px(14);
      font-weight: 600;
      color: $br-a;

      &:last-child {
        width: px(50);
        border-right: none;
      }
    }

    tr:nth-child(even) {
      background-color: tint($gy-a,70%);
    }
  }
  @include media-breakpoint-down(md) {
    table .price,
    table .title-product {
      font-size: px(14);
    }

    table .title-brand {
      font-size: px(10);
    }

    &#shopping-card {
      .image {
        display: block;
        padding: 0;
        border: none;
        overflow: hidden;
        width: 0;

        img {
          width: 0;
        }
      }

      .single {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    background-color: none;
    border-radius: 0;
    border: none;
    overflow: visible;
    box-shadow: none;
    table {
      box-shadow: none !important;
    }

    table .price,
    table .title-product {
      font-size: px(15);
    }

    table .title-brand {
      font-size: px(11);
    }

    &#shopping-card {
      .image {
        border-bottom: 1px solid $gy-a;
        padding: 0;
        width: 100px;
        display: block;
        text-align: center;
        overflow: visible;
        width: auto;
        left: 0;

        &::before {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      .single {
        display: block;
      }
    }

    table thead {
      display: none;
    }

    table {
      box-shadow: none;
      border: none;
    }

    table tbody td.image {
      display: none !important;
    }

    table tbody td {
      position: relative;
      display: block;
      background-color: $wh-a !important;
      padding: 15px 15px 10px 115px;
      border: none;
      border-right: 1px solid $gy-a !important;
      min-height: px(45);
      border-top: 1px solid $gy-a;
      &:last-child {
        border-bottom: 1px solid $gy-a;
      }
    }

    table tbody td .btn-order {
      font-size: 20px;
    }

    table tbody tr {
      position: relative;
      overflow: hidden;
      display: block;
      border-bottom: none;
      margin-bottom: px(20);

      &:last-child {
        margin-bottom: 0;
      }
    }

    table tbody tr td:last-child {
      width: 100%;
      padding-top: 10px;
      height: 50px;
    }

    table tbody td:before {
      content: attr(data-label);
      font-size: px(11);
      text-transform: uppercase;
      position: absolute;
      font-weight: 600;
      top: 0;
      left: 0;
      width: 100px;
      padding: px(15) px(20) 0;
      height: 100%;
      background-color: $br-a;
      text-align: right;
      color: $wh-a;
    }
  }
}
